import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styles from './techdoclist.module.css'

const pageQuery = graphql`
  query blogIndex {
    allMdx {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            author
            dateWritten
            headerImg {
              childImageSharp {
                  fluid(maxWidth: 100) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
            thumbnailImg {
                childImageSharp {
                    fixed(width: 100, height: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
          }
          fields {
            slug
          }
        }
      }
    }
        file(relativePath: {eq: "images/kb-logo.png"}) {
          childImageSharp {
            fixed(width: 100, height: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
  }
`

export default (props) => {
    return (
        <StaticQuery query={pageQuery}
            render={(data) => {
                const { edges: posts } = data.allMdx
                const defaultThumb = data.file.childImageSharp.fixed

                const maxItems = props.maxItems

                let sortedPosts = posts.sort((a,b) => {
                    const aDate = new Date(a.node.frontmatter.dateWritten).getTime()
                    const bDate = new Date(b.node.frontmatter.dateWritten).getTime()

                    return bDate - aDate
                })

                if (maxItems > 0) {
                    sortedPosts = sortedPosts.slice(0, maxItems)
                }

                return (
                    <ul>
                        {sortedPosts.map(({ node: post }, i) => (
                            <div key={i} className={[styles.techdoclistcontainer, styles.techdoclistcontainerborder].join(" ")}>
                                <Link activeClassName={styles.aaaa} to={post.fields.slug}>
                                    <li key={post.id} >
                                        <div className={styles.techdoclistcontainer}>
                                            <h2 className={styles.doctitle}>{post.frontmatter.title}</h2>
                                            <p className={styles.techdoclistauthor}>by {post.frontmatter.author} {post.frontmatter.dateWritten}</p>
                                            <Img className={styles.techdoclistimg} fixed={ post.frontmatter.thumbnailImg ? post.frontmatter.thumbnailImg.childImageSharp.fixed : defaultThumb} />
                                            <p className={styles.doctext}>{post.excerpt}</p>
                                        </div>
                                        <div className={styles.techdoclistcontainer}></div>
                                    </li>
                                </Link>
                            </div>
                        ))}
                    </ul>
                )
            }}
        />)
}
