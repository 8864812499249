import React from "react"

import Body from '../components/layout/default/main'
import WebappsList from "../components/widgets/webappslist/webappslist"
import TechdocList from '../components/widgets/techdoclist/techdoclist'

export default () => (
    <Body>
        <div>
            <div>
                <h2>Something Interesting Awaits!</h2>
                <p> Welcome to Kadebom!@ Hopefully this site can be a black hole for you, and help you kill some time on the internet!</p>
            </div>
            <div>
                <h2>Top Tools</h2>
                <WebappsList />
                <br />
            </div>
            <div>
                <h2>Latest Articles</h2>
                <TechdocList maxItems={5}/>
            </div>
        </div>
    </Body>
)
