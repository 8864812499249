import React from "react"
import { Link } from "gatsby"

import styles from './card.module.css'

class KCard extends React.Component {

    getContent() {
        return this.props.children
    }

    render() {
        if (this.props.href) {
            if (this.props.href.indexOf("http") > -1) {
                return (<a className={styles.cardcontainer} href={this.props.href}>
                    {this.getContent()}
                </a>)
            }

            return (
                <Link className={styles.cardcontainer} to={this.props.href}>
                    {this.getContent()}
                </Link>
            )
        }

        return (
            <div className={styles.cardcontainer}>
                {this.getContent()}
            </div>)
    }
}

export default KCard
