import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"

import KCard from '../card/card'

import styles from './webapps.module.css'

export const query = graphql`
    query {
        allWebappsJson {
          edges {
            node {
              name
              description
              version
              url
              logoImage {
                childImageSharp {
                    fixed(width: 150, height: 150) {
                        ...GatsbyImageSharpFixed
                    }
                }
              }
            }
          }
        }
      }      
  `
  

  const WebappsList = () => (
    <StaticQuery query={query}
      render={(data) => (
          <div className={styles.webappslistcontainer}>
            {data.allWebappsJson.edges.map((webapp, i) =>
              <KCard key={i} href={webapp.node.url}>
                <h2>{webapp.node.name}</h2>
                <Img fixed={webapp.node.logoImage.childImageSharp.fixed} />
                <p>{webapp.node.description}</p>
                <p className={styles.versionp}>version {webapp.node.version ? webapp.node.version : "n/a"}</p>
              </KCard>
            )}
          </div>
      )}
    />
  )

export default WebappsList